<template>
  <el-card>
    <Terms
      :terms="{ data: $tm('company.agreement.contents'), title: 'title', detail: 'detail' }"
      :agree-text="$t('company.agreement.agree')"
      v-model="isAgree"
    />
  </el-card>
  <div class="button">
    <el-button 
      type="primary"
      round
      :disabled="!isAgree"
      @click="onBtnClick"
    >
      <span>{{ $t("common.button.accept") }}</span>
    </el-button>
  </div>
</template>

<style scoped>
.el-card {
  margin: 20px;
}

div.button {
  text-align: center;
}

.el-button {
  width: 100%;
  max-width: 300px;
}
</style>

<script>
import Terms from "@/components/Terms.vue";
import { authenticateJWT } from "../../libs/common";
export default {
  components: { Terms },
  data() {
    return {
      isAgree: false,
    }
  },
  methods: {
    onBtnClick() {
      this.$store.commit("updateCompanyAgreement");
      this.$router.push({ name: "CompanyProfile" });
    },
    checkAccess() {
      let jwt = this.$store.getters.companyJWT;
      if(!jwt) {
        this.$router.push({ name: "CompanyLoginEmail" });
        return false;
      }
      if (authenticateJWT(jwt)) {
        if (this.$store.state.company.agreement) {
          this.$router.push({ name: "Company" });
        }
      }
    },
    created() {
      this.checkAccess();
    },
    beforerouteUpdate() {
      this.checkAccess();
    }
  },
  
}
</script>
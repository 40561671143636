<template>
  <div class="terms">
    <div class="content">
      <div class="hidden-xs-only-cus">
        <div v-for="item in terms.data" :key="item[terms.title]">
          <h3 class="title" v-html="item[terms.title]"></h3>
          <div v-if="Array.isArray(item[terms.detail])">
            <p class="detail" v-for="detail in item.detail" :key="detail" v-html="detail" />
          </div>
          <div v-else>
            <p class="detail" v-html="item.detail"></p>
          </div>
        </div>
      </div>
      <el-collapse class="hidden-sm-and-up">
        <el-collapse-item
          v-for="item in terms.data"
          :key="item[terms.title]"
          :title="item[terms.title]"
          :name="item[terms.title]"
        >
          <div class="accordion-content" v-html="item[terms.detail]"></div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <table class="agree">
      <tr>
        <td class="checkbox">
          <el-checkbox
            size="medium"
            v-model="checked"
            @change="updateModelValue"
          />
        </td>
        <td class="text">
          <div class="text" v-html="agreeText"></div>
        </td>
      </tr>
    </table>
  </div>
</template>

<style scoped>
.agree {
  margin-top: 20px;
}
.agree .checkbox {
  vertical-align: top;
}

.agree .text {
  padding: 0px;
  padding-left: 10px;
}

.agree >>> .el-checkbox__inner {
  zoom: 1.5 !important;
  border: 1px solid #666666;
}

.detail {
  margin: 0px;
  margin-top: 5px;
}
</style>

<script>
import "element-plus/theme-chalk/display.css";
export default {
  name: "ComponentTerms",
  props: {
    terms: {
      type: Object,
      default: () => ({
        data: [],
        title: "",
        detail: "",
      }),
    },
    agreeText: String,
    modelValue: Boolean,
  },
  emits: ["update:modelValue", "change"],
  data() {
    return {
      checked: this.modelValue,
    };
  },
  methods: {
    updateModelValue() {
      this.$emit("update:modelValue", this.checked);
    },
  },
};
</script>
